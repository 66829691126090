
























































































































































































import type {
  AiAssistantContext,
  UseCase,
  SubUseCase,
  UseCaseView
} from '@/models/AiAssistantContext'
import { Vue, Component, Watch, PropSync, Prop } from 'vue-property-decorator'
import CodelessLoading from '@/components/CodelessLoading.vue'
import AIService from '@/services/AIService'
import gql from 'graphql-tag'
import { alert, prompt } from '@/components/dialogs'
import { result } from 'lodash'

@Component({
  name: 'CreationFlowEditViewSubCases',
  components: {
    CodelessLoading
  }
})
export default class CreationFlowEditViewSubCases extends Vue {
  @PropSync('session', { type: Object }) s!: AiAssistantContext
  @Prop({ type: String, required: true }) environmentId!: string

  aiService = new AIService(this.environmentId)
  generating = false
  creatingView = false

  @Watch('environmentId', { immediate: true })
  onEnvironmentIdChange() {
    this.aiService = new AIService(this.environmentId)
  }

  @Watch('s.currentStep', { immediate: true })
  onCurrentStepChange() {
    if (this.s.currentStep === 'editViewSubCases') {
      if (this.activeUseCase && this.activeUseCase.subUseCases.length) {
        this.generating = false
      } else {
        this.generateSubCases()
      }
    } else {
      this.generating = true
    }
  }

  get selectedUseCases() {
    return this.s.useCases.filter((useCase) => useCase.value)
  }

  get activeUseCase(): UseCase | undefined {
    return this.selectedUseCases[this.s.activeUseCaseIndex]
  }

  get numberToGenerate() {
    if (!this.activeUseCase) return 0
    return Math.min(20 - this.activeUseCase.subUseCases.length, 10)
  }

  async generateSubCases() {
    if (!this.activeUseCase) return
    this.generating = true
    const subCases = this.activeUseCase.subUseCases
    const n = Math.min(this.activeUseCase.subUseCases.length + 10, 20)
    try {
      const response = await this.aiService.completions({
        model: 'text-davinci-003',
        prompt: [
          `Nombre del software: ${this.s.environmentName}`,
          `Descripción del software: ${this.s.environmentDescription}`,
          `Casos de uso operacionales del negocio:`,
          ...this.selectedUseCases.map(
            (useCase: UseCase) => `- ${useCase.text}`
          ),
          `Escribe ${n} posibles subprocesos operacionales para el caso de uso #${this.selectedUseCases.indexOf(
            this.activeUseCase
          )}:`,
          ` - ${this.activeUseCase.text}`,
          ...subCases.map((useCase: SubUseCase) => `    - ${useCase.text}`),
          `    -`
        ].join('\n'),
        temperature: 0.7,
        max_tokens: 2048
      })
      const items = response.choices[0].text.split('\n')
      for (const item of items) {
        const text = item.replace(/^\s*-\s*/g, '').replace(/\.?\s*$/g, '')
        if (text) {
          if (item.startsWith('    -')) {
            if (!this.activeUseCase.subUseCases) {
              this.activeUseCase.subUseCases = []
            }
            if (this.activeUseCase.subUseCases.length >= 20) break
            this.activeUseCase.subUseCases.push({
              text
            })
          }
        }
      }
    } catch (e) {
      console.error(e)
      alert('Se produjo un error. Intente nuevamente.')
      this.s.currentStep = 'selectSubCases'
    } finally {
      this.generating = false
    }
  }

  removeSubCase(subCase: SubUseCase) {
    if (this.activeUseCase) {
      this.activeUseCase.subUseCases = this.activeUseCase.subUseCases.filter(
        (item) => item !== subCase
      )
    }
  }

  async addCustomSubCase(useCase: UseCase) {
    const subCase = await prompt('Escribe el sub caso de uso a añadir:')
    if (!subCase) return
    useCase.subUseCases?.push({
      text: subCase as string
    })
  }

  addView() {
    if (this.activeUseCase) {
      if (this.activeUseCase.views.length >= 5) return
      this.activeUseCase.views = this.activeUseCase.views || []
      this.activeUseCase.views.push({
        name: ''
      })
      this.activeUseCase.activeViewIndex = this.activeUseCase.views.length - 1
    }
  }

  selectViewIndex(index: number) {
    if (!this.activeUseCase) return
    if (this.activeUseCase.activeViewIndex !== index) {
      this.activeUseCase.activeViewIndex = index
    } else {
      this.activeUseCase.activeViewIndex = undefined
    }
  }

  async editView(view: UseCaseView, viewIndex: number) {
    if (view.viewId) {
      return this.$router.push(
        `/${this.environmentId}/design-assistant/${view.viewId}`
      )
    }
    if (!this.activeUseCase || viewIndex < 0) return
    this.creatingView = true
    const promptParts = [
      `Nombre del software: ${this.s.environmentName}`,
      `Descripción del software: ${this.s.environmentDescription}`,
      `Caso de uso operacional: ${this.activeUseCase?.text}`,
      `Vistas y subprocesos del caso de uso:`,
      this.activeUseCase?.views
        .map(
          (view, i) =>
            `- ${view.name || `Vista #${i + 1}`}\n${
              this.activeUseCase?.subUseCases
                ?.filter((subUseCase) => subUseCase.viewIndex === i)
                .map((subUseCase) => `    - ${subUseCase.text}`)
                .join('\n') || ''
            }`
        )
        .join('\n') || '',
      `Nombre de la vista #${viewIndex + 1}:`
    ]
    try {
      const response = await this.aiService.completions({
        model: 'text-davinci-003',
        prompt: promptParts.join('\n'),
        temperature: 0.7,
        max_tokens: 2048,
        stop: ['\n']
      })
      const viewName = response.choices[0].text.trim()
      console.log({ viewName })
      promptParts[5] = `Nombre de la vista #${viewIndex + 1}: ${viewName}`
      promptParts.push(`Ruta de la vista #${viewIndex + 1}: /`)
      const response2 = await this.aiService.completions({
        model: 'text-davinci-003',
        prompt: promptParts.join('\n'),
        temperature: 0.7,
        max_tokens: 2048,
        stop: ['\n']
      })
      const viewPath = response2.choices[0].text.trim()
      console.log({ viewName, viewPath })
      view.name = viewName
      view.path = `/${viewPath}`
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation createView(
            $environmentId: ID
            $name: String
            $title: String
            $path: String
          ) {
            createView(
              environmentId: $environmentId
              name: $name
              title: $title
              path: $path
            ) {
              _id
            }
          }
        `,
        variables: {
          environmentId: this.environmentId,
          name: view.name,
          title: view.name,
          path: view.path
        }
      })
      view.viewId = data.createView._id
    } catch (e) {
      console.error(e)
      alert('Se produjo un error. Intente nuevamente.')
    } finally {
      this.creatingView = false
    }
  }

  deleteView(index: number) {
    if (!this.activeUseCase) return
    if (this.activeUseCase.views.length <= 1) return
    this.activeUseCase.subUseCases.forEach((subCase) => {
      if (subCase.viewIndex === index) {
        subCase.viewIndex = undefined
      }
    })
    this.activeUseCase.views = this.activeUseCase.views.filter(
      (view, i) => i !== index
    )
    this.activeUseCase.activeViewIndex = undefined
  }

  subCasesInViewIndex(index: number) {
    if (this.activeUseCase) {
      return this.activeUseCase.subUseCases.filter(
        (subCase) => subCase.viewIndex === index
      )
    }
    return []
  }

  subCaseDisabled(subCase: SubUseCase) {
    if (!this.activeUseCase) return true
    if (this.activeUseCase.activeViewIndex == null) return true
    if (
      subCase.viewIndex != null &&
      subCase.viewIndex !== this.activeUseCase.activeViewIndex
    )
      return true
  }

  toggleSubCase(subCase: SubUseCase) {
    if (!this.activeUseCase) return true
    if (this.activeUseCase.activeViewIndex == null) return true
    if (subCase.viewIndex === this.activeUseCase.activeViewIndex) {
      subCase.viewIndex = undefined
    } else {
      subCase.viewIndex = this.activeUseCase.activeViewIndex
    }
    this.$forceUpdate()
  }
}
