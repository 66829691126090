












































































import type { AiAssistantContext, UseCase } from '@/models/AiAssistantContext'
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import AIService from '@/services/AIService'
import CodelessLoading from '@/components/CodelessLoading.vue'
import anime from 'animejs'

@Component({
  name: 'CreationFlowSelectSubCases',
  components: {
    CodelessLoading
  }
})
export default class CreationFlowSelectSubCases extends Vue {
  @PropSync('session', { type: Object }) s!: AiAssistantContext
  @Prop({ type: String, required: true }) environmentId!: string

  aiService = new AIService(this.environmentId)

  @Watch('environmentId', { immediate: true })
  onEnvironmentIdChange() {
    this.aiService = new AIService(this.environmentId)
  }

  playingOutAnimation = false
  working = false

  @Watch('s.currentStep', { immediate: true })
  onCurrentStepChange() {
    if (this.s.currentStep === 'selectSubCases') {
      this.working = false
      this.animate()
    } else {
      this.working = true
    }
  }

  animate() {
    this.$nextTick(() => {
      anime({
        targets: '.useCaseBtn',
        opacity: [0, 1],
        scale: [0.5, 1],
        duration: 100,
        delay: anime.stagger(100)
      })
    })
  }

  selectUseCaseForView(useCase: UseCase) {
    if (this.playingOutAnimation) return
    this.s.activeUseCaseIndex = this.selectedUseCases.indexOf(useCase)
    this.playingOutAnimation = true
    this.$nextTick(async () => {
      await anime({
        targets: '.useCaseBtn:not(.selected)',
        opacity: [1, 0],
        scale: [1, 0.5],
        duration: 100,
        delay: anime.stagger(60)
      }).finished
      await anime({
        targets: '.useCaseBtn.selected',
        opacity: [1, 0],
        translateY: [0, -300],
        duration: 200,
        easing: 'easeOutQuad',
        endDelay: 500
      }).finished

      this.playingOutAnimation = false
      this.s.currentStep = 'editViewSubCases'
    })
  }

  get selectedUseCases() {
    return this.s.useCases.filter((useCase) => useCase.value)
  }

  get activeUseCase(): UseCase | undefined {
    return this.selectedUseCases[this.s.activeUseCaseIndex]
  }
}
