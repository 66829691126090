













































































import type { AiAssistantContext, UseCase } from '@/models/AiAssistantContext'
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { alert, prompt } from '@/components/dialogs'
import AIService from '@/services/AIService'
import CodelessLoading from '@/components/CodelessLoading.vue'
import anime from 'animejs'

@Component({
  name: 'CreationFlowSelectUseCases',
  components: {
    CodelessLoading
  }
})
export default class CreationFlowSelectUseCases extends Vue {
  @PropSync('session', { type: Object }) s!: AiAssistantContext
  @Prop({ type: String, required: true }) environmentId!: string

  aiService = new AIService(this.environmentId)

  @Watch('environmentId', { immediate: true })
  onEnvironmentIdChange() {
    this.aiService = new AIService(this.environmentId)
  }

  working = true

  @Watch('s.currentStep', { immediate: true })
  onCurrentStepChange() {
    if (this.s.currentStep === 'selectUseCases') {
      this.animate()
      if (this.s.useCases.length === 0) {
        return this.generateUseCases()
      }
      this.working = false
      return
    }
    this.working = true
  }

  animate() {
    this.$nextTick(() => {
      anime({
        targets: '.useCaseBtn:not(.selected)',
        opacity: [0, 1],
        translateY: [20, 0],
        duration: 100,
        delay: anime.stagger(60)
      })
    })
  }

  get selectedUseCases() {
    return this.s.useCases.filter((useCase) => useCase.value)
  }

  async generateUseCases() {
    this.working = true
    this.s.useCases = this.selectedUseCases
    try {
      const response = await this.aiService.completions({
        model: 'text-davinci-003',
        prompt: [
          `Nombre del software: ${this.s.environmentName}`,
          `Descripción del software: ${this.s.environmentDescription}`,
          `Escribe ${
            this.s.useCases.length + 10
          } posibles casos de uso operacionales del negocio (uno por línea):`,
          ...this.s.useCases.map((useCase: UseCase) => `- ${useCase.text}`),
          ` -`
        ].join('\n'),
        temperature: 0.7,
        max_tokens: 2048
      })
      const useCases = response.choices[0].text
        .split('\n')
        .map((useCase: string) => {
          return useCase.replace(/^\s*-\s*/g, '').replace(/\.?\s*$/g, '')
        })
        .filter((u: string) => u)
      this.s.useCases.push(
        ...useCases.map((useCase: string) => ({
          text: useCase,
          value: false,
          subUseCases: [],
          views: []
        }))
      )
      this.animate()
    } catch (e) {
      console.error(e)
      alert('Se produjo un error. Intente nuevamente.')
      this.s.currentStep = 'assisted'
    } finally {
      this.working = false
    }
  }

  async addCustomUseCase() {
    const useCase = await prompt('Escribe el caso de uso a añadir:')
    if (!useCase) return
    this.s.useCases.push({
      text: useCase as string,
      value: true,
      subUseCases: [],
      views: []
    })
  }
}
