


















































import { AiAssistantContext } from '@/models/AiAssistantContext'
import { Vue, Component, PropSync } from 'vue-property-decorator'

@Component({
  name: 'CreationFlowAssisted'
})
export default class CreationFlowAssisted extends Vue {
  @PropSync('session', { type: Object }) s!: AiAssistantContext

  nextLoading = false

  async startSession() {
    this.nextLoading = true
    this.s.currentStep = 'selectUseCases'
    this.nextLoading = false
  }
}
